import React, { Component } from 'react'

import Drawer from 'material-ui/Drawer'
import { List, ListItem } from 'material-ui/List'
import AppBar from 'material-ui/AppBar'
import { Router } from 'routes'

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import { grey900 } from 'material-ui/styles/colors'

import Login from 'components/Login'

import { removeToken } from 'services/auth'

import { connect } from 'react-redux'
import style from './style.css'

const muiTheme = getMuiTheme({
  userAgent: false,
  palette: {
    primary1Color: grey900,
  },
})

class Layout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: true,
    }
  }

  handleToggle = () => {
    this.setState({ open: !this.state.open })
  }

  handleClick = (name) => {
    switch (name) {
      case 'applications':
        return Router.pushRoute('/applications')
      case 'authorizations':
        return Router.pushRoute('/authorizations')
      case 'invites':
        return Router.pushRoute('/invites')
      case 'events':
        return Router.pushRoute('/events')
      case 'users':
        return Router.pushRoute('/users')
      case 'benefits':
        return Router.pushRoute('/benefits')
      case 'tags':
        return Router.pushRoute('/tags')
      case 'stats':
        return Router.pushRoute('/stats/network/1')
      case 'fund-agreements':
        return Router.pushRoute('/fund/agreements')
      case 'funds':
        return Router.pushRoute('/fund/list')
    }
  }

  renderBody() {
    const { open } = this.state
    const { logout } = this.props

    return (
      <div className={style.root}>
        <AppBar
          className={open ? style.appBar : style.appBarClosed}
          title="AYU"
          onLeftIconButtonClick={this.handleToggle}
        />
        <Drawer open={open}>
          <div className={style.drawerHeader} />
          <List>
            <ListItem primaryText="Proposals" onClick={() => this.handleClick('invites')} />
            <ListItem primaryText="Applications" onClick={() => this.handleClick('applications')} />
            <ListItem
              primaryText="Authorizations"
              onClick={() => this.handleClick('authorizations')}
            />
            <ListItem primaryText="Users" onClick={() => this.handleClick('users')} />
            <ListItem primaryText="Events" onClick={() => this.handleClick('events')} />
            <ListItem primaryText="Benefits" onClick={() => this.handleClick('benefits')} />
            <ListItem primaryText="Tags" onClick={() => this.handleClick('tags')} />
            <ListItem primaryText="Stats" onClick={() => this.handleClick('stats')} />
            <ListItem
              primaryText="Fund Agreements"
              onClick={() => this.handleClick('fund-agreements')}
            />
            <ListItem primaryText="Funds" onClick={() => this.handleClick('funds')} />
            <ListItem primaryText="Log out" onClick={() => logout()} />
          </List>
        </Drawer>
        <main className={open ? style.content : style.contentClosed}>{this.props.children}</main>
      </div>
    )
  }

  render() {
    const { loggedIn } = this.props
    const content = loggedIn ? this.renderBody() : <Login />

    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div className={style.wrap}>{content}</div>
      </MuiThemeProvider>
    )
  }
}

const mapStateToProps = (state) => ({
  loggedIn: !!state.services.auth.token,
})

const mapDispatchToProps = {
  logout: removeToken,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout)
