/**
 * Site reducer
 *
 * Very generic site actions
 *
 *
 */
import * as actionTypes from './actionTypes'

export const initialState = {
  isMobile: false,
  menuOpen: false,
  showNotificationLog: false,
}

/**
 * Handles the application errors. If no error occurred than return false
 *
 * @param {object|boolean} state
 * @param {object} action
 * @returns {object|boolean}
 */
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MOBILE:
      return {
        ...state,
        isMobile: action.isMobile,
      }

    case actionTypes.TOGGLE_MENU:
      return {
        ...state,
        menuOpen: action.visible,
      }

    case actionTypes.SHOW_NOTIFICATION_LOG:
      return {
        ...state,
        showNotificationLog: action.visible,
      }

    default:
      return state
  }
}
