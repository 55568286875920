import AyuApiClient from 'services/api/AyuApiClient'
import * as actionCreators from './actions'
import * as cookieTypes from './cookieTypes'

/**
 *
 */

export const setToken = (token: string): Function => async (
  dispatch: Function,
  getState: () => void
): Promise<any> => {
  if (window.localStorage) {
    window.localStorage.setItem(cookieTypes.AUTH_COOKIE, token)
  }

  dispatch(actionCreators.setToken(token))

  AyuApiClient.setAuthToken(token)
}

/**
 *
 */
export const removeToken = (): Function => async (
  dispatch: Function,
  getState: () => void
): Promise<any> => {
  if (window.localStorage) {
    window.localStorage.removeItem(cookieTypes.AUTH_COOKIE)
  }
  AyuApiClient.setAuthToken(null)
  dispatch(actionCreators.logout())
}

/**
 *
 */
export const checkExistingToken = (): Function => async (
  dispatch: Function,
  getState: () => void
): Promise<any> => {
  if (window.localStorage) {
    const token = window.localStorage.getItem(cookieTypes.AUTH_COOKIE)

    if (token) {
      AyuApiClient.setAuthToken(token)

      return AyuApiClient.get('me')
        .then((result) => {
          if (!result.data.attributes.admin) {
            throw new Error('Not an admin user')
          }

          dispatch(actionCreators.setToken(token))
        })
        .catch((error) => {
          AyuApiClient.setAuthToken(null)
          console.log('Token invalid', error)
        })
    }
  }

  return Promise.resolve()
}
