// @flow

import { combineReducers } from 'redux'

import { reducer as servicesReducer } from 'services/reducer'
import { reducer as formReducer } from 'redux-form'

const appReducer = combineReducers({
  services: servicesReducer,
  form: formReducer,
})

export default appReducer
