// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as authActions from 'services/auth'
import * as authApi from 'services/auth/api'
import { Dot } from 'react-animated-dots'
import AyuApiClient from 'services/api/AyuApiClient'
import TextField from 'material-ui/TextField'
import RaisedButton from 'material-ui/RaisedButton'
import style from './style.css'

class Login extends Component {
  constructor() {
    super()

    this.state = {
      error: null,
      checking: true,
      form: {},
    }

    // Refs
    this.passRef = null
    this.emailRef = null
  }

  componentDidMount() {
    if (this.props.loggedIn) {
      this.setState({
        checking: false,
      })
      return
    }

    this.props.checkExistingToken().then(() => {
      this.setState({
        checking: false,
      })
    })
  }

  onSubmit = async (e) => {
    const { email, password } = this.state.form

    e.preventDefault()

    this.setState({
      error: null,
      checking: true,
    })

    try {
      const loginResult = await authApi.login(email, password)
      const receivedToken = loginResult.data.access_token
      this.setState({
        checking: false,
      })

      AyuApiClient.setAuthToken(receivedToken)
      try {
        const meResult = await AyuApiClient.get('me')
        if (!meResult.data.attributes.admin) {
          throw new Error('Not an admin user')
        }
        this.props.setToken(receivedToken)
      } catch (error) {
        // Reset the new token. this user is a noob
        AyuApiClient.setAuthToken(null)
        this.setState({ error: 'You have to be an admin to access' })
      }
    } catch (error) {
      console.log('auth error', error)
      this.setState({
        checking: false,
        error: 'Invalid login details',
      })
    }

    return false
  }

  onInputChange = (field, value) => {
    const { error, form } = this.state

    if (error) {
      // Hide the error msg on type
      this.setState({
        error: null,
      })
    }

    const newForm = {
      ...form,
      [field]: value,
    }

    this.setState({
      form: newForm,
    })
  }

  /**
   * Renders login button or animated dots
   * I know, funky
   *
   * @return {JSX}
   */
  getButton() {
    const { checking } = this.state

    if (checking) {
      return (
        <span className={style.animatedDots}>
          <Dot>.</Dot>
          <Dot>.</Dot>
          <Dot>.</Dot>
        </span>
      )
    }

    return (
      <div>
        <RaisedButton primary label="Submit" onClick={(e) => this.onSubmit(e)} />
        <input type="submit" style={{ visibility: 'hidden' }} />
      </div>
    )
  }

  render() {
    const { children, loggedIn } = this.props
    const { checking, error } = this.state

    const content = loggedIn ? children : null
    const loginClass = loggedIn ? style.loginOk : style.login

    return (
      <div className={loginClass}>
        {content}
        <div className={style.loginWrap}>
          <form onSubmit={this.onSubmit}>
            <TextField
              floatingLabelText="Email"
              type="email"
              autoFocus
              disabled={checking}
              onChange={(event, value) => this.onInputChange('email', value)}
            />
            <TextField
              floatingLabelText="Password"
              type="password"
              disabled={checking}
              onChange={(event, value) => this.onInputChange('password', value)}
            />
            {this.getButton()}
          </form>
          <p>{error}</p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loggedIn: !!state.services.auth.token,
})

const mapDispatchToProps = {
  checkExistingToken: authActions.checkExistingToken,
  setToken: authActions.setToken,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
