import axios from 'axios'

export class AyuAuthClient {
  constructor(baseUrl = '') {
    this.baseUrl = baseUrl

    // We need a separate axios instance to remove the globally
    // assigned interceptors
    this.axios = axios.create()
    this.axios.interceptors.request.use((request) => {
      request.headers['X-Req-Origin'] = 'admin'
      return request
    })
  }

  addRequestConfig(config) {
    return {
      ...config,
      headers: {
        ...config.headers,
        'Content-Type': 'application/json',
      },
    }
  }

  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl
  }

  post(uri, payload = {}, config = {}) {
    return this.axios.post(`${this.baseUrl}${uri}`, payload, this.addRequestConfig(config))
  }
}

export default new AyuAuthClient()
