/**
 * Site reducer
 *
 * Very generic site actions
 *
 *
 */
import * as actionTypes from './actionTypes'

export const initialState = {
  token: null,
}

/**
 * Handles the application errors. If no error occurred than return false
 *
 * @param {object|boolean} state
 * @param {object} action
 * @returns {object|boolean}
 */
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      }
    case actionTypes.LOGOUT:
      return {
        ...state,
        token: initialState.token,
      }
    default:
      return state
  }
}
