import App from 'next/app'
import React from 'react'
import { Provider } from 'react-redux'
import withRedux from 'next-redux-wrapper'
import getConfig from 'next/config'
import NProgress from 'nprogress'
import { Router } from 'routes'

import { makeStore } from 'store'

import AyuApiClient from 'services/api/AyuApiClient'
import AyuAuthClient from 'services/api/AyuAuthClient'
import Layout from 'components/Layout'

const { publicRuntimeConfig } = getConfig()

AyuApiClient.setBaseUrl(publicRuntimeConfig.API_ENDPOINT)
AyuAuthClient.setBaseUrl(publicRuntimeConfig.API_ENDPOINT)

Router.events.on('routeChangeStart', (url) => {
  console.log(`Loading: ${url}`)
  NProgress.start()
})
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

class MyApp extends App {
  static async getInitialProps({ Component, router, ctx }) {
    let pageProps = {}

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }

    return { pageProps }
  }

  render() {
    const { Component, pageProps, store } = this.props
    return (
      <Provider store={store}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </Provider>
    )
  }
}

export default withRedux(makeStore)(MyApp)
