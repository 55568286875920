import * as actionTypes from './actionTypes'

export const setToken = (token) => ({
  type: actionTypes.SET_TOKEN,
  payload: token,
})

export const logout = () => ({
  type: actionTypes.LOGOUT,
})
