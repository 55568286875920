import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import reducers from 'reducers'

import { window } from 'lib/window'

export const makeStore = (initialState, options) => {
  const middlewares = [thunkMiddleware]
  return createStore(
    reducers,
    initialState,
    compose(
      applyMiddleware(...middlewares),
      window.devToolsExtension ? window.devToolsExtension() : (f) => f
    )
  )
}
