/**
 * Redux reducers
 */
import { combineReducers } from 'redux'

import { reducer as authReducer } from './auth/reducer'
import { reducer as siteReducer } from './site/reducer'

// Combine all the reducers
export const reducer = combineReducers({
  auth: authReducer,
  site: siteReducer,
})
