import AyuAuthClient from 'services/api/AyuAuthClient'
import getConfig from 'next/config'

const endpoints = {
  login: '/oauth/token',
}

const { publicRuntimeConfig } = getConfig()

/**
 * Login call
 *
 * @param {string} email
 * @param {string} password
 */
export const login = (email, password) => {
  return AyuAuthClient.post(endpoints.login, {
    grant_type: 'password',
    client_id: publicRuntimeConfig.API_CLIENT_ID || 1,
    client_secret:
      publicRuntimeConfig.API_CLIENT_SECRET || '1LPqENFlHEuA8iTF78ymGQRdcXZCzSer64uCHPHj',
    username: email,
    password,
    scope: '*',
  })
}
